import React, { Component } from 'react'

import './App.scss';

export default class App extends Component {
  render() {
    return (
      <div className='container'>
        <div>
          <h2>Admin Panel</h2>
        </div>
      </div>
    )
  }
}
