import React, { Component } from 'react'
import Auth from '../Auth'
import {Redirect,
    Route
} from "react-router-dom";
import { Input } from 'antd';
const { Search } = Input;

export default class Login extends Component {
    state={err:''}
    render() {
        return (
             <Route render={({ history}) => (
            <div style={{position:'absolute',width:'100vw',height:'100vh',background:'white',
            display:'flex',alignItems:'center',justifyContent:'center'}}>
            <Search
      placeholder="input search text"
      enterButton="Search"
      size="large"
      type='password'
      style={{ width: 400 }}
      onSearch={value => {
            Auth.saveSession(value).then(()=>{

                window.location.reload();

            }).catch(()=>{this.setState({err:'Wrong pass'})})

          }}
    />
    <div style={{color:'red'}}>{this.state.err}</div>
            </div>)}/>
        )
    }
}
