import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,Redirect
} from "react-router-dom";

import App from './App';

import User from './screens/User';
import Support from './screens/Support';
import Login from './screens/Login';


import Auth from './Auth'

import 'antd/dist/antd.css';

import { Layout, Menu, Breadcrumb } from 'antd';
const { Header, Content, Footer } = Layout;



export default class RR extends React.Component{
    render(){
         return <Router>


  <Layout className="layout">
      <Header>
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key="1"><Link to="/">Home</Link></Menu.Item>
          <Menu.Item key="2"><Link to="/support">Support</Link></Menu.Item>
          <Menu.Item key="3">nav 3</Menu.Item>
        </Menu>
      </Header>

    <Switch>
          <ProtectedRoute path="/" exact='true' component={()=><Content style={{ padding: '0 50px' }}>
                        <User/>
                </Content>}/>
{/*                 
          <Route path="/support" exact='true'>
                <Content style={{ padding: '0 50px' }}>
                    <Support/>
                </Content>
          </Route> */}

          <ProtectedRoute path="/support" exact='true' component={()=><Content style={{ padding: '0 50px' }}>
                    <Support/>
                </Content>}/>


                <Route path="/login" exact='true' >
              <Login/>
          </Route>


          <Route path="*" >
                <div>404</div>
          </Route>
        </Switch>
   

    <Footer style={{ textAlign: 'center' }}></Footer>
  </Layout>

    </Router>
    }
}


class ProtectedRoute extends React.Component{

  state = {
    isAuthenticated:true

  }

  componentWillMount(){

     this.setState({isAuthenticated:Auth.loadSession()})
  }


  render() {
    const Component = this.props.component;
    
   
    return this.state.isAuthenticated ? (
        <Component />
    ) : (
        <Login/>
    );
}
}