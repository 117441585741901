
import React from 'react';
import axios from 'axios';
import api from '../Api';
import { Layout, Menu, Breadcrumb,Card,Input,Button } from 'antd';
import moment from 'moment';


const { Header, Content, Footer, } = Layout;
const { TextArea } = Input;



export default class Ka extends React.Component{
  state={data:[]}

  componentWillMount=()=>{
    this.setState({loading:true})

    axios.get(api+'/api/support/all/?key=7028731905').then((x)=>{
            this.setState({data:x.data,loading:false})
            console.log(x.data)
        
    })
}



    render(){
        return(
 
        <div className='container'>
        <div>
          <h2>Support Panel</h2>
        </div>

        {this.state.data&&<div>
      {this.state.data.reverse().map((v,k)=> 
      <Entry v={v}/>
    )}
    </div>}

      </div>
            )
 } 
}


class Entry extends React.Component{
  state={res:''}
  send=()=>{
    axios.post(api+"/api/support/edit",{id:this.props.v._id,response:this.state.res})
    .then(()=>{
      this.setState({final:this.state.res,res:'',saved:1})

    })
  }
  render(){
    let v = this.props.v;
    
    return  <Card title={v.title} extra={<p>{moment(v.timestamp).fromNow()}
      <br/>Ticket No: {v.ticket}</p>} style={{marginBottom:10}}>
  <div style={{background:'#e6e6e6',padding:10}}>
    {v.userid&&<p>Name:{v.userid.name}</p>}
    {v.userid&&<p>Email:{v.userid.email}</p>}
    {v.userid&&<p>mobile:{v.userid.mobile}</p>}
    
  </div>
    <p>{v.description}</p>
    <p><b>Response:</b> {this.state.final||v.response}</p>
    <TextArea rows={4} value={this.state.res} onChange={(x)=>{this.setState({res:x.target.value})}} />
      <br/> <br/>
    <Button type="primary" onClick={()=>{this.send()}}>Save</Button>
    <br/><br/>
    {this.state.saved&&<p style={{color:'green'}}>Saved!</p>}
  </Card>
  }
}