
const co = '7611891292'
const pass= '22122' 


module.exports.saveSession = (pa) =>{
    
   return new Promise((res,req)=>{


    if(pa===pass){
        sessionStorage.setItem(co, '101');
        res()
    }
    else
        req()

   })

    

}

module.exports.loadSession = ()=>{

    if (sessionStorage.getItem(co)==101)
        return true
    else 
        return false

}