
import React from 'react';

import { Table, Tag, Space,DatePicker,Button,Input,Card } from 'antd';
import { Layout, Menu, Breadcrumb } from 'antd';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


import api from '../Api';

import moment from 'moment';
import axios from 'axios';


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;




export default class Ka extends React.Component{

    state={data:[],laoading:false}

    componentWillMount=()=>{
        this.setState({loading:true})

        axios.get(api+'/api/user/users/?key=7028731905').then((x)=>{
                this.setState({data:x.data,loading:false})
            
        })
    }

    save=(id,activate,btc)=>{
      console.log(btc)
        this.setState({loading:true})
        axios.post(api+'/api/user/editAdmin',{
            key:'7028731905',
            id:id,
            activation:activate,
            btc:btc,
            "EndingOn":this.state.change_date
        }).then((x)=>{
            console.log(x)
            this.setState({data:x.data,loading:false,btc:''})
        })

    }

    sendEmail=(name,email,k)=>{
      this.setState({loading:true})
      axios.post(api+'/api/user/send-activation',{
        name:name,
        email:email
      }).then(()=>{

      let data = this.state.data
      data[k].activationEmailSent = true
      this.setState({
        data:data,
        loading:false
      })
    })
    }



    columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: text => <p>{text}</p>,
        },
        {
          title: 'Ref',
          dataIndex: 'referralID',
          key: 'referralID',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Email Verified',
          dataIndex: 'emailverified',
          key: 'emailverified',
          render: tag => <p>{tag?'true':'no'}</p>
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
          },
          {
            title: 'country',
            dataIndex: 'country',
            key: 'country',
          },
          {
            title: 'Active',
            dataIndex: 'activation',
            key: 'activation',
            render: tag => <p style={{fontWeight:'bold'}}>{tag?'Yes':'No'}</p>
         
          },
          {
            title: 'Ending',
            dataIndex: 'EndingOn',
            key: 'EndingOn',
            // render: date => <p>{moment(date).format('DD/MMM/YY')}</p>
            render:(k,date,key)=> <>
            <p>{moment(k).format('DD/MMM/YY')}</p>
            <DatePicker format={'DD/MMM/YY'} onChange={(val,str)=>{

                this.setState({change_date: val})

            }} />
            </>
          },
          {
            title: 'createdDate',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: date => <p>{moment(date).format('DD/MMM/YY')} <br/> {moment(date).format('h:mma')} </p>
          },
          {
            title: 'Btc',
            dataIndex: 'btc',
            key: 'btc',
            render: data => <p>{data}</p>
          },
    
        {
          title: 'Action',
          key: 'action',
          render: (text, record,k) => (

            <div>
            <Input placeholder="Basic usage" value={this.state.btc} onChange={(v)=>this.setState({btc:v.target.value})} />
        
            <Button type="primary" onClick={()=>{this.save(this.state.data[k]._id,true, this.state.btc?(parseFloat(this.state.data[k].btc)+parseFloat(this.state.btc)).toString():(parseFloat(this.state.data[k].btc))  )}}>Active</Button>
            <Button type="link" onClick={()=>{this.save(this.state.data[k]._id,false,(parseFloat(this.state.data[k].btc)) ) }}>Deactivate</Button>

            <Button danger disabled={this.state.data[k].activationEmailSent?true:false} type="Active Email" onClick={()=>{this.sendEmail(this.state.data[k].name,this.state.data[k].email,k)}}>Send Activation Email</Button>

  </div>
          ),
        },
      ];
    




    render(){
        return(
      <div className='container'>

        <div>
        <Card title="Total Users" style={{ width: 300 }}>
    <h2>{this.state.data.length}</h2>
    </Card>

        </div>

        <br/><br/>
        <div>
            {/* <p>{this.state.change_date}</p> */}
          <h2>User Panel</h2>
        </div>
        {this.state.loading&&<Spin indicator={antIcon}/>}

        <Table loading={this.state.loading} columns={this.columns} dataSource={this.state.data} />
        
      </div>
            )
 } 
}